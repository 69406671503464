<template>
     <CForm ref="form" class="needs-validation"   >
        <CInput
            placeholder="Yeni Şifre"
            type="password"
            autocomplete="password"
            v-model="data.newPassword"
            required
            >
            <template #prepend-content>
                <CIcon name="cil-shield-alt" />
            </template>
        </CInput>
    </CForm>
</template>

<script>
    export default{
        name: "UserForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>